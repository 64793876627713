import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import video from './video.mp4';
import poster from './cover.jpg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Recaptcha from "react-recaptcha";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useToasts, ToastProvider } from 'react-toast-notifications';
import './Main.scss';
import ReactGA from 'react-ga';
import Footer from './Footer';

const Main: React.FC = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        ReactGA.pageview(document.location.pathname + document.location.search);
    }, []);

    const [modalShow, setModalShow] = useState(false);
    
    const handleClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalShow(false);
    };

    const handleShow = () => {
        ReactGA.pageview(document.location.pathname + "joinus");
        setModalShow(true);
    };

    const FormWithToasts = () => {
        return <PopupJoinUs
            show={modalShow}
            onHide={handleClose} />;
    };

    return (
        <header>
            <div className="overlay"></div>
            <video playsInline autoPlay loop muted poster={poster}>
                <source src={video} type="video/mp4" />
            </video>

            <div className="container h-100">
                <div className="d-flex h-100 text-center align-items-center">
                    <div className="w-100 text-white">
                        <img id="logo" alt="RPL S.A." src={logo} />
                        {/* <div className="fixed-bottom justify-content-center align-items-center pb-5">
                            <Button className="btn btn-light btn-lg btn-joinus-primary" onClick={handleShow}>JOIN US</Button>
                            <a href="https://blog.squaremiled.com" target="_blank" className="btn btn-light btn-lg btn-joinus-primary ml-3">BLOG</a>
                        </div> */}
                        <div className="fixed-right-bottom justify-content-right align-items-right">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ToastProvider>
                <FormWithToasts />
            </ToastProvider>
        </header>
    );

    function PopupJoinUs(props: any) {
        const { addToast } = useToasts();

        const formik = useFormik({
            initialValues: {
                name: "",
                email: "",
                message: "",
                subject: "info",
                file: "",
                fileName: "",
                recaptcha: ""
            },
            validationSchema: Yup.object({
                name: Yup.string()
                    .required('Required'),
                message: Yup.string()
                    .required('Required'),
                email: Yup.string()
                    .email('Invalid email address')
                    .required('Required'),
                file: Yup.mixed()
                    .notRequired()
                    .test('fileSize', "File is too large", value => {
                        // https://softwareengineering.stackexchange.com/questions/288670/know-file-size-with-a-base64-string
                        if (value == null) {
                            return true;
                        }

                        var val = ((value.length) * (3 / 4)) - 2;
                        return value ? val <= 1024 * 1024 * 5 : true;
                    })
                    .test('fileType', "File format is not supported (only PDF, JPG, PNG, DOCX, DOC, ODT, ZIP)", value => {
                        if (value == null) {
                            return true;
                        }

                        return value
                            ? value.includes("image/jpg") ||
                            value.includes("image/jpeg") ||
                            value.includes("image/png") ||
                            value.includes("application/pdf") ||
                            value.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
                            value.includes("application/vnd.oasis.opendocument.text") ||
                            value.includes("application/msword") ||
                            value.includes("application/zip") ||
                            value.includes("application/x-zip-compressed") ||
                            value.includes("application/x-rar-compressed")  : true;
                        }),
                recaptcha: Yup.string().required('Recaptcha is mandatory'),
            }),
            onSubmit: values => {
                //console.log(JSON.stringify(values, null, 2));
                //alert(JSON.stringify(values, null, 2));

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(values, null, 2)
                };

                fetch('Contact/PostMail' + values.subject, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            formik.setSubmitting(false);
                            addToast('Mail sent!', { appearance: 'success', autoDismiss: true });
                            handleClose();
                        },
                        // Remarque : il est important de traiter les erreurs ici
                        // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
                        // des exceptions provenant de réels bugs du composant.
                        (error) => {
                            formik.setSubmitting(false);
                            addToast('Error while sending mail...', { appearance: 'error', autoDismiss: true });
                        }
                    );
            }
        });

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        JOIN US
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="form1" onSubmit={formik.handleSubmit}>

                        <div className="form-group row">
                            <label className="col-md-4">Subject</label>
                            <div className="col-md-8">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input name="subject" id="radio_0" type="radio" className="custom-control-input" value="info" required defaultChecked onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="radio_0" className="custom-control-label">Info / Sales request</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input name="subject" id="radio_1" type="radio" className="custom-control-input" value="career" required onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="radio_1" className="custom-control-label">Career</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input name="subject" id="radio_2" type="radio" className="custom-control-input" value="parner" required onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="radio_2" className="custom-control-label">Partner</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="text" className="col-md-4 col-form-label">Name</label>
                            <div className="col-md-8">
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fa fa-address-card"></i>
                                        </div>
                                    </div>
                                    <input id="name" name="name" type="text"
                                           className={formik.touched.name && formik.errors.name ? ('form-control is-invalid') : ("form-control")}
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}/>

                                    {/* {formik.touched.name && formik.errors.name ? (
                                   //     <div>{formik.errors.name}</div>
                                   // ) : null*/}
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="email" className="col-md-4 col-form-label">Mail</label>
                            <div className="col-md-8">
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-at"></i>
                                        </div>
                                    </div>
                                    <input id="email" name="email" type="text" className={formik.touched.email && formik.errors.email ? ('form-control is-invalid') : ("form-control")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label htmlFor="message" className="col-md-4 col-form-label">Message</label>
                            <div className="col-md-8">
                                <textarea id="message" name="message" cols={40} rows={5}
                                    className={formik.touched.message && formik.errors.message ? ('form-control is-invalid') : ("form-control")}
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}></textarea>
                            </div>
                        </div>

                        {formik.values.subject == "career" ?
                            <div className="form-group row">
                                <label htmlFor="file" className="col-md-4 col-form-label">Attachment</label>
                                <div className="col-md-8">

                                    <input className={formik.touched.file && formik.errors.file ? ('customFileUpload is-invalid') : ("customFileUpload")} type="file" name="file" onBlur={formik.handleBlur} onChange={(event) => {
                                        let file = new FileReader();
                                        if (event.currentTarget.files[0] == null) {
                                            formik.setFieldValue("file", undefined);
                                            formik.setFieldValue("fileName", undefined);
                                            
                                            return;
                                        }

                                        file.readAsDataURL(event.currentTarget.files[0]);

                                        var name = event.currentTarget.files[0].name;

                                        file.onload = e => {
                                            formik.setFieldError("file", "File tooooooo large");
                                            //console.log(e.target.result);
                                            formik.setFieldValue("file", file.result);
                                            formik.setFieldValue("fileName", name);
                                        };
                                        //console.log(event.currentTarget.files[0]);
                                    }} />
                                    {formik.touched.file && formik.errors.file ? (
                                        <div>{formik.errors.file}</div>
                                    ) : null}
                                </div>
                            </div> : null}

                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Human validation</label>
                            <div className="col-md-8">
                                <Recaptcha
                                    sitekey="6Lc---EUAAAAAMZwqwFOYEKgLh9eXQPbZX-b7edp"
                                    render="explicit"
                                    theme="light"
                                    verifyCallback={(response) => { formik.setFieldValue("recaptcha", response); }}/>
                                {formik.errors.recaptcha
                                    && formik.touched.recaptcha && (
                                        <p>{formik.errors.recaptcha}</p>
                                    )}
                            </div></div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" disabled={formik.isSubmitting}>{formik.isSubmitting ? "Sending..." : "Send"}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Main;