import React, { useState, useEffect } from 'react';
import './Main.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga';
import './Footer.scss';
import CookieConsent, { Cookies } from "react-cookie-consent";
import linkedin from './lkin_logo.png';

const Footer: React.FC = () => {

    useEffect(() => {
        ReactGA.pageview(document.location.pathname + document.location.search);
    }, []);

    const [modalShow, setModalShow] = useState(false);
    const [modalCookieShow, setModalCookieShow] = useState(false);
    const [modalLegalNoticeShow, setModalLegalNotice] = useState(false);

    const handleClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalShow(false);
    };
    const handleShow = () => {
        ReactGA.pageview(document.location.pathname + "privacy");
        setModalShow(true);
    };

    const handleCookieClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalCookieShow(false);
    };
    const handleCookieShow = () => {
        ReactGA.pageview(document.location.pathname + "cookie");
        setModalCookieShow(true);
    };

    const handleLegalNoticeClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalLegalNotice(false);
    };
    const handleLegalNoticeShow = () => {
        ReactGA.pageview(document.location.pathname + "legalnotice");
        setModalLegalNotice(true);
    };

    return (
        <div className="footer">
            {/* <a href="https://bit.ly/RPL-page" target="_blank"><img id="linkedin" alt="LinkedIn" src={linkedin} /></a>
            <span> - </span> */}
            <button className="like-anchor" onClick={handleLegalNoticeShow}>LEGAL NOTICE</button>
            <span> - </span>
            <button className="like-anchor" onClick={handleShow}>PRIVACY</button>
            <span> - </span>
            <button className="like-anchor" onClick={handleCookieShow}>COOKIE POLICY</button>

            <PopupPrivacy show={modalShow} onHide={handleClose} />
            <PopupCookiePolicy show={modalCookieShow} onHide={handleCookieClose} />
            <PopupLegalNotice show={modalLegalNoticeShow} onHide={handleLegalNoticeClose} />

            <CookieConsent
                location="top"
                buttonText="I'M OKAY"
                cookieName="policyCookie"
                style={{ background: "rgba(43, 55, 59, .6)" }}
                contentStyle={{ flex: "none !important" }}
                buttonStyle={{ backgroundColor: "white", color: "black", fontSize: "13px", margin : "10px" }}
                expires={150}>
                We use cookies to personalise content and to analyse our traffic.{" "}
                <button className="like-anchor underline" onClick={handleCookieShow}>Read more</button>
            </CookieConsent>
        </div>
    );

    function PopupPrivacy(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PRIVACY POLICY
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>At rpl.lu, accessible from https://rpl.lu, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by rpl.lu and how we use it.</p>
                    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in rpl.lu. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                    <h2>Consent</h2>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                    <h2>Information we collect</h2>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                    <h2>How we use your information</h2>
                    <p>We use the information we collect in various ways, including to:</p>
                    <ul>
                        <li>Provide, operate, and maintain our webste</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>
                    <h2>Log Files</h2>
                    <p>rpl.lu follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <h2>GDPR Data Protection Rights</h2>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                    <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                    <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                    <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                    <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                    <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <h2>Children's Information</h2>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <p>rpl.lu does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function PopupCookiePolicy(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        COOKIE POLICY
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This cookie policy (&quot;Policy&quot;) describes what cookies are and how Website Operator (&quot;Website Operator&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) uses them on the <a target="_blank" rel="nofollow" href="https://rpl.lu">rpl.lu</a> website and any of its products or services (collectively, &quot;Website&quot; or &quot;Services&quot;).</p>
                    <p>You should read this Policy so you can understand what type of cookies we use, the information we collect using cookies and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies.</p>
                    <h2>What are cookies?</h2>
                    <p>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a &quot;session cookie&quot;) or for multiple repeat visits (using a &quot;persistent cookie&quot;).</p>
                    <p>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.</p>
                    <p>Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting our Website or using our Services.</p>
                    <p>Cookies may be set by the Website (&quot;first-party cookies&quot;), or by third parties, such as those who serve content or provide advertising or analytics services on the website (&quot;third party cookies&quot;). These third parties can recognize you when you visit our website and also when you visit certain other websites.</p>
                    <h2>What type of cookies do we use?</h2>
                    <h3>Analytical cookies</h3>
                    <p>These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.</p>
                    <h2>What are your cookie options?</h2>
                    <p>If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this or to learn more about cookies, visit <a target="_blank" href="https://www.internetcookies.org">internetcookies.org</a></p>
                    <h2>Changes and amendments</h2>
                    <p>We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.</p>
                    <h2>Acceptance of this policy</h2>
                    <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.</p>
                    <h2>Contacting us</h2>
                    <p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to our use of cookies, you may do so via the contact form</p>
                    <p>This document was last updated on February 20, 2023</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleCookieClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function PopupLegalNotice(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        LEGAL NOTICE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Informations</h2>
                    <p>RPL S.A. <br/>
                    3, Rue des Artisans <br />
                    L-3895 FOETZ <br />
                    LUXEMBOURG<br />
                    RCS (LU): B247309<br />
                    VAT (LU): LU32461734<br />
                    Contact: contact@rpl.lu <br />
                    </p>
                    <p>For any questions on privacy, you can consult the links on this site at the bottom of the page or contact us with the contact form.</p>
                    <h2>Web Hosting</h2>
                    <p>This site is hosted on the OVH Cloud. It is geographically located in France.</p>
                    <p>For more information, please refer to: <a target="_blank" rel="noopener noreferrer" href="https://www.ovhcloud.com/en/terms-and-conditions/privacy-policy/">OVH Privacy Policy</a></p>
                    <h2>Intellectual Property</h2>
                    <p>The Website and all of its content, unless particularly specified otherwise, are protected by intellectual property rights owned by RPL S.A.</p>
                    <p>The reproduction or use of all or part of this content, media elements included, is only allowed for a personal and private use, and provided that the source is mentioned.</p>
                    <p>Any reproduction for any other use is expressly prohibited and sanctioned by law.</p>
                    <h2>Content</h2>
                    <p>We are highly attentive to the quality of the content on this website, hence all data, including links, contained on it, are based on the knowledge available at the time of writing, but could therefore become inaccurate and outdated. We do not guarantee the accuracy or the completeness of the provided information. The user agrees that such information may be modified without prior notice. Therefore, liability for accuracy, completeness, and correctness of the information on this website and for any use of it is excluded.</p>
                    <p>RPL S.A. may not under any circumstances be held responsible for the hypertext links made toward other websites, particularly with respect to the content of such sites. Any creation of a hypertext link toward another site do not mean that RPL S.A. cautions its content and therefore RPL S.A. may not be held liable for such information, including mistakes related to addresses or domain names provided by these websites.</p>
                    <p>RPL S.A. provides its best efforts to avoid as much as possible any interruption due to technical issues. Should it happen, RPL S.A may not be held responsible for technical issues or service disruption.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleLegalNoticeClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Footer;